<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ fabric.idx + 1}} </td>

    <td  style="padding: 0px;width: 60px;">
      <input  ref="fabcode"  type="number" class="form-control" v-model="fabric.id" @keyup.enter="loadFabric(fabric.id)"/>
    </td>
    <td style="padding: 0px;">
      <button class="btn btn-outline-success" @click="loadFabric(fabric.id)" tabindex="-1" >Find</button>
    </td>
    <td style="padding: 0px;">
      {{fabric.quality.name}}
    </td>
    <td style="padding: 0px;">
      {{fabric.color.name}}
    </td>
    <td style="padding: 0px;text-align: right;padding-right: 20px;">
      {{fabric.fab_weight | weightFormat}}
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="fabric" v-model="fabric.stentering_damages" @change="rowValueEqualizer(6)" />
    </td>

    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'StenteringDetailRow',
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0}}')
      },
      myindex: {
        type: Number,
        default: () => 0
      }
    },
    beforeMount () {
      this.fabric = this.myrow;
      this.index = this.myindex;
      this.fabric.idx = this.myindex;

      // this.fabric.fabric.color =  this.fabric.color;
      // this.fabric.fabric.quality =  this.fabric.quality;
    },
    data () {
      return {
        index: 0,
        fabric : JSON.parse('{ "idx":0, "id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}')
      }
    },
    filters:{
      weightFormat (val){
        return val.toFixed(3);
      }
    },
    methods:{
      removeRow(){
        this.$emit('onRemoveRow', Math.abs(this.$data.fabric.idx) );
      },
      rowValueEqualizer(col) {
        const self = this;
        let colIndex = -1;
        try {

          let fabric = self.$data.fabric;

          switch (col) {
            case 1: //Fab Code
              fabric.stentering_damages = parseFloat(fabric);
              colIndex = 0;
              break;
            case 2: //Fab Button
              break;
            case 3: //Quality
              break;
            case 4: //Color
              break;
            case 5:  //Weight
              break;
            case 6: //Damage
              fabric.stentering_damages = parseFloat(fabric.stentering_damages);
              colIndex = 6;
              break;
          }
        }catch (e) {

        }
        self.$emit('onrow_change',self.myindex, colIndex, self.$data.fabric );
      },
      loadFabric(id){
        if(id < 1) {
          return;
        }

        const self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.fabric = JSON.parse('{ "idx":0, "id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}');
        self.$data.fabric.idx = self.myindex ;

        $('row').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/receipt/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            if(!_.isNull(resp.data)){
              self.$data.fabric = resp.data;
              self.$data.fabric.idx = self.myindex ;
              self.$emit('on_fabric_add', self.$data.fabric);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", onClose: () => { self.refs.fabcode.focus()}, timer:1500 } );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error", onClose: () => { self.refs.fabcode.focus()}, timer:1500 } );
        }).finally(function () {
          $('row').unblock();
        });
      },
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
